
  
  #firstHomePicture {
    width: 350px;
    border-radius: 50%;
    height: auto;
  }
  /* Stile für Buttons können hier hinzugefügt werden */
.button {
    text-align: center;
    border: 2px solid black;
    padding: 10px 24px;
    background-color: #b8afaf;
    font-family: "Lucida Console", "Courier New", monospace;
    margin-top: 25px;
  }
  
  .button:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }

  .aboutMe {
    background-color: #8e8888;
    display: flex;
    align-items: left;
    height: auto;
    justify-content: space-evenly;
    flex-direction: column;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 3%;
    padding-bottom: 3%;
    margin-bottom: 5%;
  }
  


  .aboutMeInnerText{
    display: flex;
    flex-direction: column;
    height: 200px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .aboutMeInnerText h3 {
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
  }

  .aboutMeInnerText p {
    line-height: 20px;
  }
  .pictureName {
    background-color: #b8afaf;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    padding-bottom: 50px;
    height: 70vh;
  }
  
  .hpSmallFont {
    color: #646262;
    font-size: 25px;
  }
  
  .hpLargeFont {
    color: black;
    font-size: 30px;
  }
  
  .hpText {
    line-height: 250%;
  }
  