.menuIcon {
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    background-color: #b8afaf;
  }
  
  .menuIcon:hover {
    filter: brightness(95%);
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #807c7f;
    color: white;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
  }
  
  .dropdown-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-content li {
    padding: 13px;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown-content li:hover {
    background-color: #b8afaf;
  }
  
  /* Link-Styling für das Dropdown */
  .menu-link {
    color: white;
    text-decoration: none;
  }
  