#aboutMeButton {
    text-align: center;
    border: 2px solid black;
    padding: 10px 24px;
    background-color: #b8afaf;
    font-family: "Lucida Console", "Courier New", monospace;
    margin-top: 25px;
    cursor: pointer;
  }
  
  #aboutMeButton:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }