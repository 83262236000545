.contact-page {
    padding-top: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .contact-page h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    padding-bottom: 5%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .contact-page p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

  }

  .email{
    padding-bottom: 5%;
  }
  
  .email a {
    font-size: 1.3rem;
    color: #0073e6;
    text-decoration: none;
  }
  
  .email a:hover {
    text-decoration: underline;
  }
  
  #social-media-icons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  #social-media-icons a {
    margin: 0 15px;
  }
  
   .icon {
    width: 70px; 
    height: 70px ;
    
  }
  
  .icon:hover {
    opacity: 0.8;
  }

#emailFormat{
  padding: 10%;
}

.emailIconTextFormat{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.emailIconTextFormat div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Gleiche Höhe für alle div-Boxen */
}

.emailIconTextFormat > div:last-child {
  margin-left: -200px; /* Abstand anpassen */
}

#emailIcon{
  height: 300px;
}