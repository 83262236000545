.projectStructure {
    display: flex;
    flex-direction: row; 
    align-items: center;  /* Beides bleibt auf gleicher Höhe */
    height: 60vh;
    padding-left: 5%; /* Abstand zum Rand */
    background-color: #8e8888;
    margin-bottom: 2%;
}

.projectStructure img {
    max-width: 600px;
    height: auto;
}

.projectStructure div:first-child {
    flex: 0 0 auto;  /* Das Bild soll seine eigene Breite behalten */
}

.projectStructure div:last-child {
    flex: 1;  /* Der Textbereich nimmt den restlichen Platz ein */
    text-align: center;  /* Zentriere den Text innerhalb seines Containers */
    margin-left:10% ;
}

.projectDescription{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.projectDescription h2{
    
    font-size: 30px;
    text-align: left;
    padding-bottom: 3%;
}

.projectDescription p{
    font-size: larger;
    text-align: left;
}

#projectHeader{
    text-align: center;
    padding-top: 2%;
    padding-bottom: 4%;
    font-size: 36px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#projectPageLayout{
    display: flex;
    flex-direction: column;
}